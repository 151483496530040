  // export const _IP = "app.income-outcome.com";//Production Andromeda
  // export const _IP = 'localhost';
  export const _IP = "qa.income-outcome.com" //QA production
  //export const _IP = "3.15.18.129";//PEQUEÑA Produccion Linkthink comparte Econdominium
  //const _IP = '3.142.90.167';//PEQUEÑA Produccion Linkthinks
  //const _IP = '18.219.10.167';//MEDIANA no disponible
  //const _IP = '3.143.219.23';//GRANDE no disponible
  
// export const _URL_SERVICES_BE = 'http://'+_IP+':6007/api/general';
export const _URL_SERVICES_BE = 'https://'+_IP+':6010/api/general';
// export const _URL_SERVICES_BE ='https://app.income-outcome.com:6007/api/general';//production;

export const _URL_AUTH = _URL_SERVICES_BE + '/login';
export const _URL_USERS = _URL_SERVICES_BE + '/users';
export const _URL_FACILITATORS = _URL_SERVICES_BE + '/facilitator';
export const _URL_DISTRIBUTORS = _URL_SERVICES_BE + '/distributor';
export const _URL_LANGUAGES = _URL_SERVICES_BE + '/languages';
export const _URL_LABELS = _URL_SERVICES_BE + '/labels';
export const _URL_TIMEZONES = _URL_SERVICES_BE + '/timezone';
export const _URL_WORKSHOP = _URL_SERVICES_BE + '/workshop';
export const _URL_EMAILS = _URL_SERVICES_BE + '/emails';
export const _URL_CLIENTS = _URL_SERVICES_BE + '/client';
export const _URL_BOARD = _URL_SERVICES_BE + '/board';
export const _URL_TEAMS = _URL_SERVICES_BE + '/team';
export const _URL_RULES = _URL_SERVICES_BE + '/rules';
export const _URL_BALANCE = _URL_SERVICES_BE + '/balance';
export const _URL_ORDER = _URL_SERVICES_BE + '/order';
export const _URL_IMPROVEMENT = _URL_SERVICES_BE + '/improvement';
export const _URL_IncomeStatement = _URL_SERVICES_BE + '/incomestatement';
export const _URL_REPORTS = _URL_SERVICES_BE + '/reports';
export const _URL_Chat = _URL_SERVICES_BE + '/chat';

